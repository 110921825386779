import React, { useRef, useState } from 'react';
import './PurchaseOrderProductCard.scss';
import { DeliverySchedule, PurchaseProduct } from '../../models/Task';
import { formatDate } from '../../utils/Helper';
import { useDispatch } from 'react-redux';
import {
  addProductDeliveryScheduleStart,
  deleteProductDeliveryScheduleStart,
  updateDeliveryDetailsStart,
  updatePurchaseOrderProductStart,
} from '../../redux/oms/omsActions';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

interface PurchaseOrderProductCardProps {
  product: PurchaseProduct;
  vendorId: number;
  vendorIndex: number;
  productIndex: number;
}

const PurchaseOrderProductCard: React.FC<PurchaseOrderProductCardProps> = ({
  product,
  vendorId,
  vendorIndex,
  productIndex,
}) => {
  const [newDeliverySchedule, setNewDeliverySchedule] = useState<string>('');
  const [deliveryOpen, setDeliveryOpen] = useState<string>('');
  const [deliveryDate, setDeliveryDate] = useState<string>('');
  const [clientDeliveryDate, setClientDeliveryDate] = useState<string>('');
  const quantityRef = useRef<null | HTMLDivElement>(null);
  const [completionOpen, setCompletionOpen] = useState<boolean>(false);

  const dispatch = useDispatch();

  const updateDeliveryDetails = (
    key:
      | 'delivered_quantity'
      | 'expected_vendor_delivery_date'
      | 'track_link'
      | 'expected_delivery_date',
    delivery_id: number,
    prtpm_id: number,
    vendor_id: number,
    value: string
  ) => {
    dispatch(
      updateDeliveryDetailsStart({
        id: delivery_id,
        prtpm_id,
        vendor_id,
        key,
        value,
      })
    );
    setDeliveryOpen('');
  };

  const handleDueDateChange = async (
    dueDate: string | undefined,
    type: 'vendor' | 'client'
  ) => {
    if (dueDate) {
      toggleDeliveryDateSelector('');
      if (type === 'vendor') {
        setDeliveryDate(formatDate(dueDate));
      } else {
        setClientDeliveryDate(formatDate(dueDate));
      }
    }
  };

  const handleComplettionDateChange = async (
    completionDate: string | undefined
  ) => {
    if (completionDate) {
      setCompletionOpen(false);
      dispatch(
        updatePurchaseOrderProductStart({
          completion_date: formatDate(completionDate),
          id: product.prtpm_id,
          vendor_id: vendorId,
        })
      );
    }
  };

  const toggleDeliveryDateSelector = (
    key: string,
    type?: 'vendor' | 'client' | 'new-vendor' | 'new-client'
  ) => {
    if (deliveryOpen === `${type}-${key}`) {
      setDeliveryOpen('');
    } else {
      setDeliveryOpen(`${type}-${key}`);
    }
  };

  const deleteSchedule = (id: number) => {
    dispatch(deleteProductDeliveryScheduleStart(id));
  };

  const submitNewSchedule = (prtpm_id: number) => {
    if (
      quantityRef.current &&
      (deliveryDate !== '' || clientDeliveryDate !== '')
    ) {
      const formData = new FormData();
      formData.append('prtpm_id', prtpm_id.toString());
      formData.append('quantity', quantityRef.current.innerHTML);
      formData.append('expected_vendor_delivery_date', deliveryDate);
      formData.append('expected_delivery_date', clientDeliveryDate);
      dispatch(addProductDeliveryScheduleStart(formData));
      setNewDeliverySchedule('');
      setDeliveryDate('');
      setClientDeliveryDate('');
    }
  };

  return (
    <div className="purchase-order-product-card flex-column gap-5 ">
      <div className="upper-container flex-row align-items-center justify-content-space-between p-8">
        <div className="flex-row gap-8">
          <a
            href={`/skus/${product.product_id}`}
            className="xetgo-font-tag-bolder"
            style={{ textDecoration: 'none', color: '#000000' }}
            target="_blank"
            rel="noreferrer"
          >
            {product.name},
          </a>
          <span style={{ color: '#969696' }} className="xetgo-font-tag">
            {product.material}
          </span>
        </div>
        <div className="flex-row align-items-center gap-16">
          <p className="xetgo-font-tag flex-row gap-10 subtitle-colour">
            Completion Date:
          </p>
          <p
            style={{ color: '#484848' }}
            className="xetgo-font-tag bolder flex-row align-items-center gap-12"
          >
            {product.completion_date}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={dayjs()}
                format={'DD-MM-YYYY'}
                views={['year', 'month', 'day']}
                onChange={(event) =>
                  handleComplettionDateChange(event?.toDate().toISOString())
                }
                open={completionOpen}
                className="delivery-date-selector new-date-entry"
              />
            </LocalizationProvider>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
              onClick={() => setCompletionOpen((val) => !val)}
              className="cursor-pointer"
              height={16}
              width={16}
            />
          </p>
        </div>
      </div>

      <div className="product-card-middle-container-detail flex-row gap-10 xetgo-font-tag px-8">
        <p className="description py-8 px-14 flex-1">{product.description}</p>
        <div className="flex-row gap-4">
          <div className="main-box flex-column align-items-center justify-content-center gap-4 py-15 px-18">
            <p className="title-colour bolder">
              {product.quantity} {product.unit}
            </p>
            <p className="subtitle-colour">Quantity</p>
          </div>
          <div className="main-box flex-column align-items-center justify-content-center gap-4 py-15 px-18">
            <p className="title-colour bolder">
              {product.cost_price > 0 ? '₹' : '-'} {product.cost_price}
            </p>
            <p className="subtitle-colour">Cost Price</p>
          </div>
          <div className="main-box flex-column align-items-center justify-content-center gap-4 py-15 px-18">
            <p className="title-colour bolder">
              {product.selling_price > 0 && '₹'} {product.selling_price}
            </p>
            <p className="subtitle-colour">Selling Price</p>
          </div>
        </div>
      </div>
      <div className="delivery-container flex-column gap-12 p-8">
        <div className="flex-row justify-content-space-between">
          <p className="xetgo-font-tag-bolder">Delivery</p>
          <span
            className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
            style={{
              color: '#5151EC',
              width: 'fit-content',
            }}
            onClick={() =>
              setNewDeliverySchedule(`${vendorIndex}-${productIndex}`)
            }
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
              alt="plus"
              width={16}
              height={16}
            />
            Add Delivery Details
          </span>
        </div>

        {product.delivery_schedules.map(
          (delivery: DeliverySchedule, scheduleIndex: number) => {
            return (
              <div
                className="po-detail-delivery-detail-container px-8 gap-18"
                key={scheduleIndex}
              >
                <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                  <p>Delivered Quantity</p>
                  <div
                    style={{ color: '#484848' }}
                    className="xetgo-font-tag-bolder delivery-input"
                    contentEditable={true}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        updateDeliveryDetails(
                          'delivered_quantity',
                          delivery.id,
                          delivery.prtpm_id,
                          vendorId,
                          (e.target as HTMLDivElement).innerHTML
                        );
                      }
                    }}
                    dangerouslySetInnerHTML={{
                      __html: delivery.delivered_quantity?.toString(),
                    }}
                  ></div>
                  <p className="xetgo-font-tag-bolder"> U</p>
                </div>
                <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                  <p>Expected Vendor Dispatch Date</p>
                  <div
                    style={{ color: '#484848' }}
                    className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(delivery.expected_vendor_delivery_date)}
                        format={'DD-MM-YYYY'}
                        views={['year', 'month', 'day']}
                        onChange={(event) =>
                          updateDeliveryDetails(
                            'expected_vendor_delivery_date',
                            delivery.id,
                            delivery.prtpm_id,
                            vendorId,
                            event?.toDate().toISOString() || ''
                          )
                        }
                        open={
                          deliveryOpen === `vendor-${delivery.id.toString()}`
                        }
                        className="delivery-date-selector"
                      />
                    </LocalizationProvider>
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                      onClick={() =>
                        toggleDeliveryDateSelector(
                          delivery.id.toString(),
                          'vendor'
                        )
                      }
                      className="calendar-icon cursor-pointer"
                      height={16}
                      width={16}
                      alt=""
                    />
                  </div>
                </div>
                <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                  <p>Expected Client Delivery Date</p>
                  <div
                    style={{ color: '#484848' }}
                    className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(delivery.expected_delivery_date)}
                        format={'DD-MM-YYYY'}
                        views={['year', 'month', 'day']}
                        onChange={(event) =>
                          updateDeliveryDetails(
                            'expected_delivery_date',
                            delivery.id,
                            delivery.prtpm_id,
                            vendorId,
                            event?.toDate().toISOString() || ''
                          )
                        }
                        open={
                          deliveryOpen === `client-${delivery.id.toString()}`
                        }
                        className="delivery-date-selector"
                      />
                    </LocalizationProvider>
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                      onClick={() =>
                        toggleDeliveryDateSelector(
                          delivery.id.toString(),
                          'client'
                        )
                      }
                      className="calendar-icon cursor-pointer"
                      height={16}
                      width={16}
                      alt=""
                    />
                  </div>
                </div>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
                  alt="delete"
                  width={20}
                  height={20}
                  className="cursor-pointer"
                  onClick={() => deleteSchedule(delivery.id)}
                />
                <a
                  href={`/delivery-detail/${delivery.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="flex-row align-items-center"
                >
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20241010065237-eye.svg"
                    alt="details"
                    width={20}
                    height={20}
                    className="cursor-pointer"
                  />
                </a>
              </div>
            );
          }
        )}
        {newDeliverySchedule === `${vendorIndex}-${productIndex}` && (
          <div className="po-detail-new-delivery-detail-container px-8 gap-18">
            <div className="flex-row price-box-container xetgo-font-tag px-8 py-4 gap-4">
              <p>Delivered Quantity</p>
              <div
                style={{ color: '#484848' }}
                className="xetgo-font-tag-bolder delivery-input"
                ref={quantityRef}
                contentEditable={true}
              ></div>
              <p className="xetgo-font-tag-bolder"> U</p>
            </div>
            <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
              <p>Expected Vendor Delivery Date</p>
              <p
                style={{ color: '#484848' }}
                className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
              >
                {deliveryDate}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs()}
                    format={'DD-MM-YYYY'}
                    views={['year', 'month', 'day']}
                    onChange={(event) =>
                      handleDueDateChange(
                        event?.toDate().toISOString(),
                        'vendor'
                      )
                    }
                    open={
                      deliveryOpen ===
                      `new-vendor-${vendorIndex}-${productIndex}`
                    }
                    className="delivery-date-selector new-date-entry"
                  />
                </LocalizationProvider>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                  onClick={() =>
                    toggleDeliveryDateSelector(
                      `${vendorIndex}-${productIndex}`,
                      'new-vendor'
                    )
                  }
                  className="cursor-pointer"
                  height={16}
                  width={16}
                />
              </p>
            </div>
            <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
              <p>Expected Client Delivery Date</p>
              <p
                style={{ color: '#484848' }}
                className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
              >
                {clientDeliveryDate}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs()}
                    format={'DD-MM-YYYY'}
                    views={['year', 'month', 'day']}
                    onChange={(event) =>
                      handleDueDateChange(
                        event?.toDate().toISOString(),
                        'client'
                      )
                    }
                    open={
                      deliveryOpen ===
                      `new-client-${vendorIndex}-${productIndex}`
                    }
                    className="delivery-date-selector new-date-entry"
                  />
                </LocalizationProvider>
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                  onClick={() =>
                    toggleDeliveryDateSelector(
                      `${vendorIndex}-${productIndex}`,
                      'new-client'
                    )
                  }
                  className="cursor-pointer"
                  height={16}
                  width={16}
                />
              </p>
            </div>
            <div
              className="submit-delivery-schedule cursor-pointer flex-row align-items-center justify-content-center"
              onClick={() => submitNewSchedule(product.prtpm_id)}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240106094117-green_tick.svg"
                alt="green-tick"
                width={11}
                height={9}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PurchaseOrderProductCard;
